import React, { lazy, useEffect } from 'react';
import { IonBackdrop, useIonToast } from '@ionic/react';
import { refresh } from 'ionicons/icons';
import TagManager from 'react-gtm-module';

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

import SuspenseLoader from 'components/suspense-loader';
import { useAuthContext } from 'contexts/AuthContext';
import { ImgixProvider } from 'contexts/ImgixContext';
import { ThemeColors } from 'constants/theme-colors';
import environmentUtil from 'utils/environmentUtil';

const SignIn = lazy(() => import('../sign-in'));
const Tabs = lazy(() => import('../tabs'));
const ErrorPage = lazy(() => import('../error'));

const App: React.FC = () => {
	const { isAuthLoading, isAuthenticated } = useAuthContext();
	const [present] = useIonToast();

	const tagManagerArgs = {gtmId : 'GTM-WMJT6K5'};

	const reloadPage = (registration : ServiceWorkerRegistration) => {
		// the active service worker is outdated and the updated service worker is waiting
		// the below function tells the waiting service worker to skip the wait
		// please reference service-worker.ts
		registration.waiting?.postMessage({type : 'SKIP_WAITING'});

		// once the waiting service worker becomes the active one, force reload the app for new content
		registration.waiting?.addEventListener('statechange', (event : any) => {
			if (event.target?.state === 'activated') {
				window.location.reload();
			}
		});
	};

	const presentToast = (registration : ServiceWorkerRegistration) => {
		present({
			message  : 'New version available. Refresh to update.',
			position : 'top',
			color    : ThemeColors.THRILLING_LIME,
			buttons  : [{
				side    : 'end',
				icon    : refresh as any,
				handler : () => reloadPage(registration),
			}],
		});
	};

	useEffect(() => {
		// SW registration register function accepts Config
		// pass callback function presentToast as the onUpdate handler
		// the function is called when there's an updated service worker
		serviceWorkerRegistration.register({onUpdate : (registration) => presentToast(registration)});

		// initialize GTM in production
		if (environmentUtil().isProduction) {
			TagManager.initialize(tagManagerArgs);
		}
	}, []);

	if (isAuthLoading) {
		return (
			<IonBackdrop />
		);
	}

	if (!isAuthLoading && !isAuthenticated) {
		return (
			<SuspenseLoader>
				<SignIn />
			</SuspenseLoader>
		);
	}

	if (!isAuthLoading && isAuthenticated) {
		return (
			<SuspenseLoader>
				<ImgixProvider>
					<Tabs />
				</ImgixProvider>
			</SuspenseLoader>
		);
	}
	return (
		<SuspenseLoader>
			<ErrorPage />
		</SuspenseLoader>
	);

};

export default App;

