import { 
	getFirestore, 
	collection, 
	query, 
	doc, 
	updateDoc, 
	getDocs,
	Query,
	FirestoreError, 
} from 'firebase/firestore';

import firebase from 'utils/firebase';

const db = getFirestore(firebase);

export function get(ref: string): Promise<any> {
	const q = query(collection(db, ref));

	return getDocs(q)
		.then((data) => {
			const result: Array<any> = [];
			data.forEach((doc) => {
				result.push(doc.data());
			});
			return result;
		}).catch((error) => {
			console.log(error);
			return error;
		});
}

export function getCountWithQuery(query: Query): Promise<any> {
	return getDocs(query)
		.then((snapshot: any) => {
			snapshot.size; 
		});
}
		
export function getWithQuery(query: Query): Promise<any> {
	return getDocs(query)
		.then((snapshot: any) => {
			const result: Array<any> = [];
			snapshot.forEach((document: any) => {
				result.push(document.data());
			});
			return result;
		}).catch((error: FirestoreError) => {
			console.log(error);
			return error;
		});
}

export function update(ref: string, document: string, value: object): Promise<any> {
	const docRef = doc(db, ref, document);

	return updateDoc(docRef, value)
		.catch((error) => {
			console.log(error);
		});
}
