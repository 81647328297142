export const TRACK_FEATURES = {
	FEATURE : {
		PRODUCT_CREATE   : {NAME : 'Product Create'},
		PRODUCT_DETAIL   : {NAME : 'Product Detail'},
		PRODUCT_EDIT     : {NAME : 'Product Edit'},
		PRODUCT_OVERVIEW : {NAME : 'Products List Overview'},
		PRICE_SUGGESTION : {NAME : 'Product Price Suggestion'},
		STORE_EDIT       : {NAME : 'Store Edit'},
		STORE_OVERVIEW   : {NAME : 'Store Overview'},
	},
};