import { initializeApp } from 'firebase/app';
import ReactGA from 'react-ga4';

import environmentUtil from './environmentUtil';

const firebaseConfig = {
	apiKey            : process.env.REACT_APP_API_KEY,
	authDomain        : process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL       : process.env.REACT_APP_DATABASE_URL,
	projectId         : process.env.REACT_APP_PROJECT_ID,
	storageBucket     : process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId : process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId             : process.env.REACT_APP_APP_ID,
	measurementId     : process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Google Analytics
if (environmentUtil().isProduction) {
	ReactGA.initialize(firebaseConfig.measurementId as string);
}

const firebase = initializeApp(firebaseConfig);
export default firebase;
