import React from 'react';
import { IonPage, IonContent, IonText } from '@ionic/react';

const ErrorPage: React.FC = () => {
	return (
		<IonPage>
			<IonContent>
				<IonText className='prompt-message'>
					<h2>Something went wrong</h2>
					<p>Please try again or contact support@shopthrilling.com.</p>
				</IonText>
			</IonContent>
		</IonPage>
	);
};

export default ErrorPage;