import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import App from './pages/app/App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

/* Global Style */
import './theme/index.scss';

import { SentryProvider } from 'contexts/SentryContext';
import environmentUtil from 'utils/environmentUtil';

defineCustomElements(window);
const queryClient = new QueryClient({
	defaultOptions : {
		queries : {
			retry                : false,
			refetchOnWindowFocus : environmentUtil().isProduction ?? true,
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<SentryProvider>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<App />
				</AuthProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</SentryProvider>
	</React.StrictMode >,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
