import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios, { AxiosError } from 'axios';

import ErrorPage from '../pages/error/';

import { TRACK_FEATURES  } from 'constants/tracking/features';
import environmentUtil from 'utils/environmentUtil';

type SentryContextType = {
	logSentryError: (error: any, title: string, userId: string, userEmail: string, transactionId: string) => void;
	TRACK_FEATURES: any;
};
interface SentryProviderProps {
	children: JSX.Element;
}

const SentryContext = React.createContext<SentryContextType>({} as SentryContextType);

const useSentryContext = (): SentryContextType => React.useContext(SentryContext);

const logSentryError = (error: Error | AxiosError, title: string, userId: string, userEmail: string, transactionId: string) : void => {
	// https://github.com/getsentry/sentry-javascript/blob/5ed31354/packages/types/src/severity.ts#L13
	const level = Sentry.Severity.Error;
	
	Sentry.configureScope(scope => {	
		scope.setUser({
			id    : userId,
			email : userEmail,
		});

		scope.setTag('transaction_id', transactionId);
	});

	Sentry.addBreadcrumb({
		category : 'Error',
		message  : axios.isAxiosError(error) ? error.response?.data.error : error.message,
		level,
	});

	Sentry.captureMessage(title, {level});
};

const SentryProvider = (props: SentryProviderProps): JSX.Element => {
	if (environmentUtil().isProduction) {
		Sentry.init({
			dsn          : 'https://5d4a083a82234413acc153f77397f6d1@o1090948.ingest.sentry.io/6107499',
			integrations : [new Integrations.BrowserTracing()],
			ignoreErrors : [
				'ResizeObserver loop limit exceeded', 
				'Error: User cancelled photos app',
				'FirebaseError',
				'ChunkLoadError',
			],
	
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate : 0.5,
		});
	}

	return (
		<Sentry.ErrorBoundary fallback={<ErrorPage />}>
			<SentryContext.Provider
				value={{
					logSentryError : logSentryError,
					TRACK_FEATURES,
				}}>
				{props.children}
			</SentryContext.Provider>
		</Sentry.ErrorBoundary>
	);
};

export { SentryProvider, useSentryContext, logSentryError };

