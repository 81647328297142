import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';
import { 
	getFirestore, 
	collection, 
	query, 
	where, 
	Query, 
} from 'firebase/firestore';

import firebase from 'utils/firebase';
import { getWithQuery } from 'services/firebase/client';

const db = getFirestore(firebase);

export function useGetUserStatusByEmail(email: string, options?: UseQueryOptions<any, Error>): QueryObserverResult<any, Error> {
	return useQuery<any, Error>(['userStatuses', { email }], () => getWithQuery(initGetUserStatusByEmailQuery(email)), options);
}

export function initGetUserStatusByEmailQuery(email: string): Query<any> {
	return query(
		collection(db, 'userStatuses'), 
		where('email', '==', email),
	);
}
