import React, { useState } from 'react';
import {
	getAuth,
	signOut,
	onAuthStateChanged,
	getRedirectResult,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	GoogleAuthProvider,
	signInWithRedirect,
	OAuthProvider,
	UserCredential,
	sendPasswordResetEmail,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';

import { initGetUserByEmailQuery } from 'services/users/queries';
import { getWithQuery, update } from 'services/firebase/client';
import { initGetUserStatusByEmailQuery } from 'services/user-statuses/queries';
import Toast from 'components/toast';

type AuthContextType = {
	token: string;
	isAuthLoading: boolean;
	isAuthenticated: boolean;
	isAdmin: boolean;
	user: any;
	signOut: () => void;
	signInWithGoogle: () => void;
	signInWithApple: () => void;
	signInWithEmailAndPassword: (email: string, password: string) => void;
	resetPassword : (email : string) => Promise<void>, 
	signUpWithEmailAndPassword: (email: string, password: string) => void;
};

interface AuthProviderProps {
	children: JSX.Element;
}

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

const useAuthContext = (): AuthContextType => {
	return React.useContext(AuthContext);
};

const AuthProvider = (props: AuthProviderProps): JSX.Element => {

	const [authState, setAuthState] = React.useState({ isAuthLoading : true } as AuthContextType);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');

	const auth = getAuth();

	const handleGoogleLogin = () => {
		const googleAuthProvider = new GoogleAuthProvider();
		signInWithRedirect(auth, googleAuthProvider);
	};

	const handleAppleLogin = () => {
		const appleAuthProvider = new OAuthProvider('apple.com');
		signInWithRedirect(auth, appleAuthProvider);
	};

	const handleEmailandPasswordLogin = (email: string, password: string) => {
		const lowercasedEmail = email?.toLowerCase();

		if (!lowercasedEmail) {
			return;
		}

		signInWithEmailAndPassword(auth, lowercasedEmail, password)
			.catch((error) => handleError(error));
	};

	const  handlePasswordReset = async (email : string) : Promise<void> => {
		const lowercasedEmail = email?.toLowerCase();

		if (!lowercasedEmail) {
			return;
		}

		await sendPasswordResetEmail(auth, lowercasedEmail);
	};

	const postLoginSignupSuccess = async (userCredential : UserCredential) => {
		/** After a successful user creation, the user record needs to be updated:
		 * 1 - Find the User record via email
		 * 2 - Craft the updates object
		 * 3 - Update the user record
		*/

		const userRecord = await getWithQuery(initGetUserByEmailQuery(userCredential.user.email ?? ''));
		const userRecordUpdates = {
			uid         : userCredential.user.uid,
			provider    : userCredential.user?.providerData?.[0]?.providerId,
			displayName : userCredential.user?.providerData?.[0]?.displayName || userCredential.user?.email,
			image       : userCredential.user?.providerData?.[0]?.photoURL,
		};
		await update('users', userRecord[0].id, userRecordUpdates);


		/** Once the user record is updated, the userStatus record needs to be updated:
			 * 1 - Find the UserStatus record via email
			 * 2 - Craft the updates object
			 * 3 - Update the user status record
			 */

		const userStatusRecord = await getWithQuery(initGetUserStatusByEmailQuery(userCredential.user.email ?? ''));
		const userStatusRecordUpdates = {provider : userCredential.user?.providerData?.[0]?.providerId};
		await update('userStatuses', userStatusRecord[0].id, userStatusRecordUpdates);
	};

	const handleEmailandPasswordSignUp = (email: string, password: string) => {
		const lowercasedEmail = email?.toLowerCase() ?? '';

		createUserWithEmailAndPassword(auth, lowercasedEmail, password)
			.then(async (userCredential: UserCredential) => {
				await postLoginSignupSuccess(userCredential);
			})
			.catch((error) => handleError(error));
	};

	const handleSignOut = () => {
		signOut(auth);
	};

	const handleError = (error : FirebaseError) : void => {
		if (error.code === 'auth/wrong-password') {
			setToastMessage('Your password is incorrect');
		} else if (error.code === 'auth/weak-password') {
			setToastMessage('Password must be at least 6 characters');
		} else if (error.code === 'auth/network-request-failed') {
			setToastMessage('Please check your internet connection');
		} else if (error.code === 'auth/too-many-requests') {
			setToastMessage('We have detected too many requests from your device. Please wait a few minutes before trying again.');
		} else {
			setToastMessage('An unknown error occurred');
		}

		setShowToast(true);
	};

	React.useEffect(() => {
		onAuthStateChanged(auth, async (firebaseUser) => {
			try {
				// First, check to see if firebaseUser is null. This would be true for a user that isn't logged in, or a user that just logged out.
				if (firebaseUser === null) {
					return setAuthState({
						token                      : '',
						isAuthenticated            : firebaseUser ? true : false,
						isAdmin                    : false,
						user                       : firebaseUser ? firebaseUser : null,
						isAuthLoading              : false,
						signInWithGoogle           : handleGoogleLogin,
						signInWithApple            : handleAppleLogin,
						signOut                    : handleSignOut,
						signInWithEmailAndPassword : handleEmailandPasswordLogin,
						resetPassword              : handlePasswordReset,        
						signUpWithEmailAndPassword : handleEmailandPasswordSignUp,
					});
				}

				const redirectResults = await getRedirectResult(auth);
				let isAdmin = false;

				// If it's a user that's been logged in already, check to see if they're an admin
				const users: Array<any> = await getWithQuery(initGetUserByEmailQuery(firebaseUser.email as string));
				const user = users.pop();
				if (user?.adminRoles) {
					isAdmin = true;
				}

				// If it's a redirect user, then check to see if they're valid
				if (redirectResults?.user) {
					const userStatuses: Array<any> = await getWithQuery(initGetUserStatusByEmailQuery(redirectResults.user?.email as string));

					if (!userStatuses || userStatuses.length === 0) {
						handleSignOut();
					}

					await postLoginSignupSuccess(redirectResults);
				}

				const idToken = await firebaseUser.getIdToken();

				setAuthState({
					token                      : idToken,
					isAuthenticated            : firebaseUser ? true : false,
					isAdmin,
					user                       : firebaseUser ? firebaseUser : null,
					isAuthLoading              : false,
					signInWithGoogle           : handleGoogleLogin,
					signInWithApple            : handleAppleLogin,
					signOut                    : handleSignOut,
					signInWithEmailAndPassword : handleEmailandPasswordLogin,
					resetPassword              : handlePasswordReset, 
					signUpWithEmailAndPassword : handleEmailandPasswordSignUp,
				});
			} catch (error) {
				console.error(error);
			}
		});
	}, []);


	return (
		<>
			<AuthContext.Provider
				value={authState}>
				{props.children}

				<Toast isOpen={showToast} message={toastMessage} onDismiss={() => setShowToast(false)} />
			</AuthContext.Provider >
		</>
	);
};

export { AuthProvider, useAuthContext };
