import { ResponseType } from 'axios';

// Assume the React app will make decisions based on environment rather than its own URL

interface EnvironmentUtils {
	baseDomain: string,
	baseURL: string,
	get: string,
	isProduction: boolean,
	responseType: ResponseType,
}

const getBackendBaseDomain = () => {
	// NODE_ENV has three preset values of 'development', 'test', and 'production'
	// that cannot be expanded or modified. Therefore, we are going to use a custom
	// environment variable of our own to set the URLs so that we can have many
	// different potential environments.
	const env = process.env.REACT_APP_ENV;
	let domain = '';
	switch (env) {
		case 'production':
			domain = process.env.REACT_APP_PROD_API_DOMAIN || 'production.pokeapi.co';
			break;
		case 'staging':
			domain
				= process.env.REACT_APP_STAGING_API_DOMAIN || 'staging.pokeapi.co';
			break;
		case 'development':
			domain = process.env.REACT_APP_DEV_API_DOMAIN || 'localhost:5001/thrilling-dev-portal/us-central1/apiv1';
			break;
		default:
			domain = process.env.REACT_APP_DEV_API_DOMAIN || 'localhost:5001/thrilling-dev-portal/us-central1/apiv1';
			break;
	}
	return domain;
};

const getBackendBaseUrl = () => {
	const domain = getBackendBaseDomain();
	if (domain.includes('localhost'))
		return `http://${ domain }`;


	return `https://${ domain }`;
};

// const buildURL = (src: any) => {
// 	if (src.indexOf('blob:') < 0)
// 		return `${ getBackendBaseUrl() }${ src[0] === '/' ? '' : '/' }${ src }`;


// 	return src;
// };

export default (): EnvironmentUtils => ({
	baseDomain   : getBackendBaseDomain(),
	baseURL      : getBackendBaseUrl(),
	get          : process.env.NODE_ENV,
	isProduction : process.env.REACT_APP_PROJECT_ID === 'thrilling-portal',
	responseType : 'json',
});
