import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

import environmentUtils from 'utils/environmentUtil';
import { Avif, Webp } from 'constants/test-images';


type ImgixContextType = {
	isProduction : boolean
	imgFormat    : 'avif' | 'webp' | 'pjpg'
}

interface ImgixProviderProps {
	children: ReactNode;
}

const ImgixContext = createContext<ImgixContextType>({} as ImgixContextType);

const useImgixContext = (): ImgixContextType => {
	return useContext(ImgixContext);
};

const ImgixProvider = ({children}: ImgixProviderProps): JSX.Element => {
	const { isProduction } = environmentUtils();
	const [imgFormat, setImgFormat] = useState<ImgixContextType['imgFormat']>('pjpg');

	const checkImgFormatSupport = () : void => {
		const avif = new Image();
		avif.src = Avif;
		avif.onload = () => setImgFormat('avif');

		avif.onerror = () => {
			const webp = new Image();
			webp.src = Webp;
			webp.onload = () => setImgFormat('webp');
		};
	};

	useEffect(() => {
		checkImgFormatSupport();
	}, []);

	return (
		<ImgixContext.Provider
			value={{
				isProduction,
				imgFormat,
			}}>
			{children}
		</ImgixContext.Provider >
	);
};

export { ImgixProvider, useImgixContext, ImgixContext };
