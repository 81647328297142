import { IonToast } from '@ionic/react';
import React from 'react';

import { ThemeColors } from 'constants/theme-colors';

const Toast : React.FC<{
	isOpen         : boolean,
	onDismiss?     : () => void,
	message?       : string,
	transactionId? : string,
}> = ({ isOpen, onDismiss, message, transactionId }) : JSX.Element => {
	const messageText = message
		? message
		: transactionId
			? `An error has occurred with reference ID ${ transactionId }`
			: 'An error has occurred. Please contact support for more information.';

	return <IonToast
		isOpen={isOpen}
		onDidDismiss={onDismiss}
		message={messageText}
		color={ThemeColors.SYSTEM_RED}
		position='top'
		buttons={[{
			icon    : 'close',
			side    : 'end',
			handler : onDismiss,
		}]}
	/>;
};

export default Toast;
