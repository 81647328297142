import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {getAuth} from 'firebase/auth';

import firebase from '../../utils/firebase';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function createAxiosInstance(transactionId? : string) : Promise<AxiosInstance> {
	const updatedToken = await getAuth(firebase).currentUser?.getIdToken(true);

	return axios.create({
		baseURL : API_BASE_URL,
		headers : {
			'token'        : updatedToken,
			'content-type' : 'application/json',
			...(transactionId && { 'X-Transaction-ID' : transactionId }),
		},
		paramsSerializer : (params) => {
			return Object.keys(params).map((key) => {
				return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
			}).join('&');
		},
	});
}

/**
 *
 * @param objectType a string representing the API endpoint, such as 'stores', or 'products'
 * @param instanceId a string representing the id of the firestore document
 */
export async function getOne(objectType : string, instanceId : string) : Promise<any> {
	const instance = await createAxiosInstance();

	return instance.get(`/${ objectType }/${ instanceId }`)
		.then((response : AxiosResponse) => {
			return response.data;
		});
}

/**
 *
 * @param objectType a string representing the API endpoint, such as 'stores', or 'products'
 * @param queryParams an optional object containing any where queries to be used in the firestore query
 */
export async function get<T>(objectType : string, queryParams : object = {}) : Promise<T> {
	const instance = await createAxiosInstance();

	return instance.get(`/${ objectType }`, { params : queryParams })
		.then((response : AxiosResponse) => {
			return response.data;
		});
}

/**
 *
 * @param objectType a string representing the API endpoint, such as 'stores', or 'products'
 * @param data the new data to be posted
 */
export async function post(objectType : string, data : object, transactionId? : string) : Promise<any> {
	const instance = await createAxiosInstance(transactionId);

	return instance.post(`/${ objectType }`, data)
		.then((response : AxiosResponse) => {
			return response.data;
		});
}

/**
 *
 * @param objectType a string representing the API endpoint, such as 'stores', or 'products'
 * @param instanceId a string representing the id of the firestore document
 * @param data the data to update
= */
export async function put(objectType : string, instanceId : string, data : object, transactionId? : string) : Promise<any> {
	const instance = await createAxiosInstance(transactionId);

	return instance.put(`/${ objectType }/${ instanceId }`, data)
		.then((response : AxiosResponse) => {
			return response.data;
		});
}

/**
 *
 * @param data the data to update
= */
export async function putLoggedInUser(data : object, transactionId? : string) : Promise<any> {
	const instance = await createAxiosInstance(transactionId);

	return instance.put('/logged-in-user', data)
		.then((response : AxiosResponse) => {
			return response.data;
		});
}
