import { QueryObserverResult, useMutation, UseMutationOptions, UseMutationResult, useQuery } from 'react-query';
import {
	getFirestore,
	collection,
	query,
	where,
	Query,
} from 'firebase/firestore';

import firebase from 'utils/firebase';
import { get, getWithQuery } from 'services/firebase/client';
import { get as apiGet, putLoggedInUser as apiPut } from 'services/api/client';

const db = getFirestore(firebase);

export function useGetUsers(): QueryObserverResult<any, Error> {
	return useQuery(['getUsers'], () => get('users'));
}

export function useGetLoggedInUser(): QueryObserverResult<any, Error> {
	return useQuery('loggedInUser', async () => apiGet('logged-in-user'), { useErrorBoundary : true });
}

export function useUpdateLoggedInUser(transactionId : string, options? : UseMutationOptions<any, Error, object, Error>) : UseMutationResult<any, Error, object, Error> {
	return useMutation(async (updates : object) => apiPut(updates, transactionId), options);
}

export function useGetUserById(id: number): QueryObserverResult<any, Error> {
	return useQuery(['getUserById'], () => getWithQuery(initGetUserByIdQuery(id.toString())));
}

export function initGetUserByEmailQuery(email: string): Query<any> {
	return query(
		collection(db, 'users'),
		where('email', '==', email),
	);
}

export function initGetUserByIdQuery(id: string): Query<any> {
	return query(
		collection(db, 'users'),
		where('id', '==', id),
	);
}
