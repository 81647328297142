import React, { Suspense } from 'react';
import { IonLoading } from '@ionic/react';
interface SuspenseLoaderProps {
	children: React.ReactNode,
}

const SuspenseLoader = (props: SuspenseLoaderProps): JSX.Element => {
	return (
		<Suspense fallback={<IonLoading isOpen={true} />}>
			{props.children}
		</Suspense>
	);
};

export default SuspenseLoader;
